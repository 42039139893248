import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { H2 } from "@/components/atoms/text/Headings.tsx";
import type { QA } from "@/types/qa";
import { PortableText } from "@portabletext/react";
import { Fragment } from "react";

interface QuestionsAndAnswersProps {
  qa?: QA[];
}

const QuestionsAndAnswers = ({ qa }: QuestionsAndAnswersProps) => {
  return (
    <div className={"max-w-3xl mx-auto w-full"}>
      <H2>Ofte stilte spørsmål</H2>
      <Accordion type={"single"} collapsible={true}>
        {qa?.map((item) => (
          <Fragment key={item.question}>
            <AccordionItem value={item.question}>
              <AccordionTrigger>{item.question}</AccordionTrigger>
              <AccordionContent className={"w-full"}>
                <div className={"prose prose-slate w-full max-w-none"}>
                  <PortableText value={item.answer} />
                </div>
              </AccordionContent>
            </AccordionItem>
          </Fragment>
        ))}
      </Accordion>
    </div>
  );
};

export default QuestionsAndAnswers;
